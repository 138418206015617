.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.multi-range-slider{
  box-shadow: none!important;
  border: none!important;
  padding: 10px 0px!important;
}

.multi-range-slider .bar-left, .multi-range-slider .bar-right, .multi-range-slider .bar-inner{
  box-shadow: none!important;
  border: none!important;
}
.multi-range-slider .thumb::before{
  box-shadow: none!important;
  border: none!important;
}
.multi-range-slider .thumb .caption *{
  color: #000!important;
  background-color: #fff!important;
  border: none!important;
  box-shadow: 0px 0px 2px black!important;
  padding: 0 10px!important;
}
.multi-range-slider .bar {
  height: 4px;
}


/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}



/* Slider 2 */

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 10px; /* Space below slider */
}

.slick-dots {
  position: relative;
  bottom: -15px; /* Adjusted for larger dots */
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 30px; /* Increased size */
  height: 30px; /* Increased size */
  margin: 0 10px; /* Adjust spacing between dots */
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100%; /* Full width of li */
  height: 100%; /* Full height of li */
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 20px; /* Increased font size for larger dot */
  line-height: 30px; /* Adjust line height to match new height */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

/* Base styles for the slider */
.slick-list {
  width: 100%; /* Ensures slider is always as wide as its container */
}

/* Adjust the width of the slider based on screen size, only when not in zoom state */
@media (max-width: 768px) {
  .non-zoom-state {
    width: 75%; /* Reduces width to 75% for medium screens */
  }
}

@media (max-width: 400px) {
  .non-zoom-state {
    width: 65%; /* Reduces width to 65% for small screens */
  }
}

/* Specific styles for zoom mode on small and medium screens */
@media (max-width: 768px) {
  .fixed.inset-0.p-\[130px\].z-\[99999999\] {
    padding: 20px; /* Reduced padding for smaller screens */
    width: 100%;
  }

  .fixed.top-20.right-20 {
    top: 20px; /* Adjust close button position for better reachability */
    left: 10px;
  }

  .lg\:h-\[800px\] {
    height: auto; /* Make the image height responsive */
    max-height: 75vh; /* Limit image height to 75% of the viewport height */
    width: auto; /* Ensure width is auto to maintain aspect ratio */
    margin: auto; /* Center the image horizontally */
  }
}

@media (max-width: 768px) {
  .fixed.inset-0.p-\[130px\].z-\[99999999\] {
    padding: 50px; /* Further reduced padding for very small screens */
  }

  .lg\:h-\[800px\] {
    max-height: 50vh; /* Further limit image height on very small devices */
  }
}

/* Ensure the image is centered and covers adequately during zoom */
.zoom-img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto; /* Centers the image in the available space */
  position: relative; /* Ensures positioning is relative to the zoom container */
}

/* w-12 overide */

/* Default styles assuming zoom is true */
.slider-zoom-active {
  width: 50%; /* Same as w-1/2 */
  margin: auto; /* Same as m-auto */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .slider-zoom-active {
    width: 80%; /* Larger width on small screens */
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .h-52 {
    height: 10rem; /* Example of reducing height on smaller screens */
  }
}

/* Finally Heading!!*/

@media (max-width: 400px) {
  .responsive-heading {
    font-size: smaller;  /* Reduce the font size on small screens */
    line-height: 1.4;    /* Increase line height for better readability when text wraps */
  }

  .responsive-heading span {
    display: block; /* Optional: Force the address to drop to the next line */
  }
}

